type PermissionMapType = {
  //数据看板
  dashboard: {
    dashboard: { [key: string]: string }
  }
  //设备管理
  device: {
    devicelist: { [key: string]: string }
    templatelist: { [key: string]: string }
    devicegroup: { [key: string]: string }
  }
  //计费管理
  billing: {
    myAccount: { [key: string]: string }
    rechargeRecord: { [key: string]: string }
    // rechargeRecord: "充值记录",
  }
  //消息中心
  message: {
    msgBilling: { [key: string]: string }
    // profile: "个人中心",
  }
  //系统管理
  system: {
    user: { [key: string]: string }
    role: { [key: string]: string }
    safeZone: { [key: string]: string }
  }
}

export const subMenuList = [
  "dashboard",
  "devicelist",
  "templatelist",
  "devicegroup",
  "myAccount",
  "rechargeRecord",
  "msgBilling",
  "user",
  "role",
  "safeZone"
]

export type MenuType = keyof PermissionMapType
export type SubMenuType = (typeof subMenuList)[number]

export const permissionMap: PermissionMapType = {
  //数据看板
  dashboard: {
    dashboard: {
      1: "dashboard-view"
    }
  },
  //设备管理
  device: {
    devicelist: {
      2: "devicelist-view",
      3: "devicelist-bind",
      4: "devicelist-edit",
      5: "devicelist-delete",
      6: "devicelist-hmi",
      7: "devicelist-config"
    },
    templatelist: {
      8: "templatelist-view",
      9: "templatelist-add",
      10: "templatelist-copy",
      11: "templatelist-edit",
      12: "templatelist-delete",
      36: "templatelist-data-pm"
    },
    devicegroup: {
      13: "devicegroup-view",
      14: "devicegroup-add",
      15: "devicegroup-edit",
      16: "devicegroup-delete",
      37: "devicegroup-data-pm"
    }
  },
  //计费管理
  billing: {
    myAccount: {
      17: "myAccount-view",
      18: "myAccount-ca-charge",
      19: "myAccount-ca-allcoate",
      20: "myAccount-ra-charge",
      21: "myAccount-ra-allcoate"
    },
    rechargeRecord: {
      22: "rechargeRecord-view"
    }
  },
  //消息中心
  message: {
    msgBilling: {
      23: "msgBilling-view"
    }
    // profile: "个人中心",
  },
  //系统管理
  system: {
    user: {
      24: "user-view",
      25: "user-invite",
      26: "user-edit",
      27: "user-delete",
      38: "user-data-pm"
    },
    role: {
      28: "role-view",
      29: "role-add",
      31: "role-edit",
      32: "role-delete"
    },

    safeZone: {
      33: "safeZone-view",
      30: "safeZone-add",
      34: "safeZone-edit",
      35: "safeZone-delete"
    }
  }
}

//一级菜单数组
export const getMenuList = () => Object.keys(permissionMap) as MenuType[]

//二级菜单数组
export const getSubMenuList = () => subMenuList

//三级菜单对象集合
export const getOpList = () => {
  let obj: { [key: string]: string } = {}
  getMenuList().map((menu) => {
    getSubMenuListByMenu(menu).map((subMenu) => {
      Object.keys((permissionMap[menu] as any)[subMenu]).map((key) => {
        obj[key] = (permissionMap[menu] as any)[subMenu][key]
      })
    })
  })
  return obj
}

//二级菜单数组(某个一级菜单下的所有二级菜单)
export const getSubMenuListByMenu = (menu: MenuType) => Object.keys(permissionMap[menu])

//三级级权限数组(某个二级菜单下的所有三级权限)
export const getOpListBySubMenu = (menu: MenuType | null, subMenu: SubMenuType) => {
  let arr: string[] = []
  if (menu === null) {
    getMenuList().map((menuItem) => {
      if (permissionMap[menuItem].hasOwnProperty(subMenu)) {
        arr = Object.keys((permissionMap[menuItem] as any)[subMenu]).sort((a, b) => {
          //按照插入顺序排序
          const obj = (permissionMap[menuItem] as any)[subMenu]
          return obj[a].includes("view") ? -1 : obj[b].includes("view") ? 1 : 0
        })
      }
    })
  } else {
    arr = Object.keys((permissionMap[menu] as any)[subMenu]).sort((a, b) => {
      const obj = (permissionMap[menu] as any)[subMenu]
      return obj[a].includes("view") ? -1 : obj[b].includes("view") ? 1 : 0
    })
  }

  return arr
}

//三级级权限名称
export const getOpName = (menu: MenuType | null, subMenu: SubMenuType, op: string) => {
  let name: string = ""
  if (menu === null) {
    getMenuList().map((menuItem) => {
      if (permissionMap[menuItem].hasOwnProperty(subMenu)) {
        name = (permissionMap[menuItem] as any)[subMenu][op]
      }
    })
  } else {
    name = (permissionMap[menu] as any)[subMenu][op]
  }
  return name
}

//返回权限所包含的一二级菜单
export const getOpBelong = (op: string[]) => {
  let arr: [MenuType, SubMenuType, boolean][] = []
  outer: for (const menu in permissionMap) {
    if (arr.find((item) => item.includes(menu))) {
      break outer
    }
    inner: for (const subMenu in permissionMap[menu as MenuType]) {
      const opList = getOpListBySubMenu(menu as MenuType, subMenu as SubMenuType)
      if (arr.find((item) => item.includes(subMenu))) {
        break inner
      }

      if (opList.find((item) => op.includes(item))) {
        const hasAllOps = opList.every((item) => op.includes(item))
        arr.push([menu as MenuType, subMenu, hasAllOps])
      }
    }
  }
  return arr.length > 0 ? arr : null
}
