import { request } from "@/utils/axios"
import { parseQueryStr } from "@/utils"

export const getLoginKey = () => {
  return request({
    url: "/global/login",
    method: "get"
  })
}

type LoginForm = {
  username: string
  password: string
}

export const login = (data: LoginForm) => {
  return request<{ token: string; update: boolean; url: string, rtoken: string }>({
    url: "/global/login?type=1",
    method: "post",
    manual: true,
    data: {
      user: data.username,
      passwd: data.password
    }
  })
}
export type SwitchParams = {
  token: string
  true_name: string
  data_center: string
  cellphone: string
  account: string
  passwd: string
}

export const useremoval = (params: SwitchParams) => {
  return request<any>({
    url: "/global/removal",
    method: "post",
    headers: {
      Authorization: params.token
    },
    data: {
      account: params.account,
      true_name: params.true_name,
      data_center: params.data_center,
      contact: params.cellphone,
      passwd: params.passwd
    }
  })
}

type PhoneVerifyParam = {
  phone: string
  resend: number
}
export const phoneVerify = (data: PhoneVerifyParam) => {
  return request<string>({
    url: `/global/phoneverify?${parseQueryStr(data)}`,
    method: "post",
    data
  })
}

export type RegisterParam = {
  type: "phone" | "mail"
  cellphone?: string
  email?: string
  password: string
  true_name: string
  data_center: string
  vcode?: string
}

export const register = (data: RegisterParam) => {
  // 1 mail 2 phone
  const pType = data.type == "phone" ? 0 : 1
  const params = {
    username: data.type == "phone" ? data.cellphone : data.email,
    ...data
  }
  data.type == "phone" ? delete params.cellphone : delete params.email

  return request<string>({
    url: `/global/register?type=${pType}`,
    method: "post",
    manual: true,
    data: params
  })
}

export const activateMail = (params: { n: any; t: any }) => {
  return request<string>({
    url: `/global/activate`,
    method: "get",
    params,
    manual: true
  })
}

export const resendmail = (data: RegisterParam) => {
  // 1 mail 2 phone
  const pType = data.type == "phone" ? 0 : 1
  const params = {
    username: data.type == "phone" ? data.cellphone : data.email,
    ...data
  }
  data.type == "phone" ? delete params.cellphone : delete params.email

  return request<string>({
    url: `/global/resendmail?type=${pType}`,
    method: "post",
    data: params
  })
}

export const getUserInfo = () => {
  return request<any>({
    url: `/global/getUserInfo`,
    method: "get"
  })
}
// 1手机 0 邮箱
export type ForgetverifyParams = {
  account: string
  // 1 phone 0 mail
  type: 1 | 0
  resend: number
}

export const forgetverify = (data: ForgetverifyParams) => {
  return request<any>({
    url: `/global/forgetverify`,
    method: "post",
    data
  })
}

export type ModifyPwdParams = {
  account: string
  vcode: string
  pwd: string
}

export const modifyPwd = (data: ModifyPwdParams) => {
  return request<any>({
    url: `/global/modifyPwd`,
    method: "post",
    data
  })
}

export const editUserInfo = (data: any) => {
  return request<Array<any>>({
    url: `/editUserInfo`,
    method: "post",
    data
  })
}

export const changePwd = (data: any) => {
  return request<Array<any>>({
    url: `/global/changePwd`,
    method: "post",
    data
  })
}

export const refreshToken = (refreshtoken: string, token: string) => {
  return request<any>({
    url: `/refreshtoken?from=web`,
    method: "post",
    data: { token },
    manual: true,
    headers: {
      Authorization: refreshtoken
    }
  })

}