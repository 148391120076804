export default {
  common: {
    accountSetting: "账户设置",
    passwordSetting: "密码设置",
    pi: "请输入{0}",
    ps: "请选择{0}",
    and: "和",
    account: "账号",
    verificationCode: "验证码",
    password: "密码",
    currentPassword: "当前密码",
    goLogin: "已有账号,去登录?",
    confirm: "确定",
    confirm2: "确认",
    cancel: "取消",
    confirmPassword: "确认密码",
    confirmSettings: "确认设置",
    newPassowrd: "新密码",
    userName: "用户名",
    dataCenter: "数据中心",
    appDownload: "移动端下载页",
    mail: "邮箱",
    europe: "欧洲",
    theUnitedStates: "美国",
    australia: "澳大利亚",
    china: "中国",
    india: "印度",
    phone: "手机号",
    len: "{0}长度至少为{1}个字符",
    registerSuccessConfirm: "注册成功,是否前往登录?",
    registerSuccessPLogin: "注册成功,请登录!",
    confirmDiff: "确认密码与密码不一致",
    newPwdSame: "当前密码不可与当前密码一致",
    newPwdDiff: "确认密码与新密码不一致",
    passwordFormatError: "8-16位, 至少包含数字、字母或符号中的两项或以上",
    phoneAndMailFormatError: "手机号码/邮箱格式错误",
    phoneFormatError: "手机号码格式错误",
    mailFormatError: "邮箱格式错误",
    successfully: "成功",
    close: "关闭",
    operation: "操作",
    query: "查询",
    pageTotal: "共 {0} 条",
    pageTotal2: "共 {0} 台",
    submit: "提交",
    min: "请输入不少于{0}个字符",
    max: "请输入少于{0}个字符",
    minMaxLimit: "请输入{0}-{1}个字符",
    basic: "基本",
    tag: "TAG",
    screen: "组态画面",
    alarm: "告警",
    loginSuccessfully: "登录成功!",
    logoutSuccessfully: "退出登录成功!",
    read: "读",
    write: "写",
    readWrite: "读写",
    edit: "编辑",
    delete: "删除",
    tip: "提示",
    updateSuccessfully: "更新成功!",
    alarmLevel1: "非常紧急",
    alarmLevel2: "普通",
    alarmLevel3: "比较紧急",
    online: "在线",
    offline: "离线",
    logout: "退出登录",
    settings: "设置",
    colorScheme: "配色方案",
    menuScheme: "菜单颜色",
    lightMode: "日间模式",
    darkMode: "夜间模式",
    light: "浅色",
    dark: "深色",
    operationSuccessfully: "操作成功!",
    valName: "变量名称",
    create: "创建",
    yes: "是",
    no: "否",
    getUserInfoFaild: "获取用户信息失败, 请重新登录!",
    switchToNew: "请完善用户基本信息",
    switchToNewMsg: "完善用户信息成功!",
    resetPassword: "重置密码",
    permission: "权限",
    roleName: "角色名称",
    unbindCurrDevice: "确定删除设备",
    unbindCurrDeviceMemo: "删除该设备后，监控该设备产生的数据将被删除且不可恢复",
    changePasswordSuccessfully: "修改密码成功, 请重新登录!",
    changeProfileSuccessfully: "修改用户信息成功!",
    noData: "暂无数据",
    backToLogin: "返回登录页",
    mailResultMemo1: "感谢注册！激活邮件已发送至您的邮箱：",
    mailResultMemo2: "请在48小时内登录邮箱，按照邮箱中的提示完成激活",
    mailResultMemo3: "没有收到验证码怎么办?",
    mailResultMemo4: "1、检查是否在邮件的广告邮件、垃圾邮件中",
    mailResultMemo5: "2、确认未收到邮件，请尝试",
    reSend: "重新发送",
    activeMail: "激活邮件",
    moveTo: "移动到...",
    groupPickerMemo1: "可移动到已创建的任一组级中",
    groupPickerMemo2: "请选择要放置的组级位置",
    deleteTemplate: "删除模板",
    deleteTemplateConfirm: "是否删除模板",
    activeResult: "激活结果",
    dataCenterPickerMemo1: "您的设备数据将存储于所选的数据中心，请谨慎选择",
    templateLimit: "您的模板个数已达上限"
  },
  login: {
    loginPlatform: "登录DIACloud云平台",
    account: "账号",
    password: "密码",
    rememberPassword: "记住密码",
    phoneAndMailAndUsername: "手机号码/邮箱",
    readAndAgree: "我已阅读并同意",
    serviceAgreement: "服务协议",
    privacyAgreement: "隐私政策",
    registerNow: "立即注册",
    forgotPassword: "忘记密码",
    login: "登录",
    goLogin: "前往登录",
    agreeMemo: "请阅读并同意服务协议与隐私政策",
    cookieTips1: "本网站使用cookies",
    cookieTips2: "我们使用cookies来改善您在网站上的体验，我们尊重您的选择，并致力于为您提供安全的浏览体验。",
    reject: "拒绝",
    accept: "接受"
  },
  register: {
    registerWay: "注册方式",
    register: "注册",
    phoneRegistration: "手机号注册",
    mailRegistration: "邮箱注册",
    submit: "提交注册",
    getVarifyCode: "获取验证码",
    second: "秒",
    sendToMuch: "发送次数过多",
    contactMemo: "用于消息设备-接收渠道信息,不用于账号登录"
  },
  dashboard: {
    dashboard: "数据看板",
    collectionEmpty: "暂无收藏设备",
    goto: "前往",
    download: "下载",
    favoriteDevice: "收藏设备",
    deviceOverview: "设备信息",
    hmiScreen: "HMI屏",
    config: "组态画面",
    thumbnail: "缩略图",
    viewDcreen: "查看组态画面",
    editScreen: "编辑组态画面",
    configScreen: "组态画面",
    hmiBind: "HMI绑定",
    billingDetail: "计费详情",
    basicInfo: "基本信息",
    configUpdate: "组态更新",
    hmiScreenSN: "HMI屏SN",
    hmiGatewaySN: "HMI网关SN",
    deviceName: "设备名称",
    deviceSN: "设备SN",
    deviceModel: "设备型号",
    deviceGroup: "设备分组",
    relateTemplate: "关联模板",
    deviceAddress: "设备地址",
    remark: "备注",
    configCreate: "组态创建",
    configModify: "组态修改",
    maskSuccessfully: "收藏成功!",
    removeMaskSuccessfully: "取消收藏成功!",
    editModelMemo: "当前信息未保存,切换标注将会放弃所有修改数据?",
    notAssociated: "未关联",
    editScreenMsg1: "请使用 DIAWeb Designer 编辑画面",
    editScreenMsg2: "如果您的电脑未安装 DIAWeb Designer",
    please: "请"
  },
  device: {
    unBindDevice: "删除设备",
    unBindDeviceOk: "删除设备成功",
    deviceManage: "设备管理",
    deviceList: "设备列表",
    templateList: "模板列表",
    deviceGroup: "设备分组",
    tunnel: "Tunnel状态",
    groupSameName: "分组重名",
    rename: "重命名",
    addGroup: "添加分组",
    addChildGroup: "添加子分组",
    delete: "删除",
    deleteSucccessful: "删除{0}成功!",
    groupName: "分组名称",
    defaultGroup: "默认分组",
    setDefaultGroup: "设为默认分组",
    templateName: "模板名称",
    hmiModel: "HMI屏型号",
    deviceCount: "设备数量",
    publishTime: "创建时间",
    queryNamePh: "模板名称",
    queryModelPh: "HMI屏型号",
    addTemplate: "添加模板",
    copyTemplate: "复制模板",
    deviceName: "设备名称",
    deviceSN: "设备SN",
    description: "客户名称",
    deviceModel: "设备型号",
    hmiScreenModel: "HMI屏型号",
    cloneTemplate: "克隆模板",
    remark: "备注",
    templateInfo: "模板信息",
    hmiScreen: "HMI屏",
    fw: "固件",
    recipe: "配方",
    versionNumber: "版本号",
    createTime: "创建时间",
    configurationState: "组态",
    configurationStateCreateTime: "组态创建时间",
    configurationStatemodifyTime: "组态修改时间",
    tagName: "TAG名称",
    dataType: "数据类型",
    addrType: "地址类型",
    address: "地址",
    rwType: "读写类型",
    scanTime: "扫描时间",
    saveHistroy: "存历史",
    deviceNameAndTemplateName: "设备名称/关联模板",
    alarmLevel: "告警级别",
    hmiDongleState: "HMI网关状态",
    hmiGraph: "HMI画面",
    hmiDongleAsync: "HMI与云端同步",
    deviceTag: "设备标注",
    tagEmpty1: "暂无标注,",
    createTag: "新建标注",
    editTag: "编辑标注",
    deleteTag: "删除标注",
    addTag: "添加标注",
    tagNumLimit: "标注上限50个",
    checkTagNumLimit: "选中标注上限10个",
    createAndSearchTag: "创建/搜索标注",
    addDevice: "添加设备",
    syncState1: "已同步",
    syncState0: "待同步",
    cloudLastVer: "云端最新版本",
    hmiThisVer: "HMI版本",
    asyncTooltipTitle: "HMI本地与云端配置同步情况(请前往HMI-系统更新,进行同步)",
    labelOver: "标注长度限制1-50个字符",
    labelSame: "标注名称已存在",
    labelNumLimit: "标注数量达到50个上限",
    deviceChangeGroup: "设备移组",
    changeGroupSuccessfully: "移组成功!",
    addModel: "新增型号",
    templateDesign: "组态设计",
    unbindLabel: "删除标注",
    unbindLabelConfirm: "删除该标注会同步删除其他设备已绑定的同名标注, 确定删除?",
    templateDesignConfirm: "是否编辑设备已加入的模板{0}?"
  },
  template: {
    defaultValue: "默认值",
    description: "描述",
    used: "是否被引用",
    templateMemo: "模板介绍",
    bindTemplateTip: "确定绑定模板",
    templateLockTip1: "该模板已过期，请激活Cloud Application计费卡，充值模板数量",
    templateLockTip2:
      "TIP: 激活Cloud Application计费卡后，从最近创建的模板开始恢复，请控制好您的模板总数，以免您需要的模板无法恢复使用"
  },
  message: {
    messageCenter: "消息中心",
    alarm: "告警",
    system: "系统公告",
    billingMsg: "计费消息",

    billingMsgType1: "Remote Access到期提醒",
    billingMsgType2: "Cloud Application到期提醒",
    billingMsgType3: "设备TAG到期提醒",
    billingMsgType4: "设备流量不足提醒",
    billingMsgType5: "账号TAG不足提醒",
    billingMsgType6: "邀请通知",
    billingMsgType7: "取消邀请提醒 ",
    billingMsgType8: "移出团队提醒",
    billingMsgType9: "主账号转让通知",

    readAllTip: "已全部标记已读",
    total: "全部",
    unread: "未读",
    msgType: "消息类型",
    startDate: "选择开始时间",
    endDate: "选择结束时间",
    readAll: "全部已读",
    billing: "计费",
    notifications: "公告",
    warnings: "告警",
    noUnread: "暂无消息",
    noUnreadToday: "暂无未读消息",
    goAllMsg: "查看全部",
    monthTraffic: "本月消耗流量",
    open: "已开启",
    close: "已关闭",
    continued: "继续使用",
    closeTraffic: "关闭流量",
    todayNews: "今日消息 ({0}条)",
    trafficTips1: "是否确认开启流量？",
    trafficTips2: "开启后本月不可再关闭流量，请谨慎操作",
    trafficTips3: "若流量不足，仍需继续使用，请在【计费管理-我的账户】中充值Remote Acces计费卡并分配给本设备使用",
    trafficTips4: "本月已消耗12G流量，是否继续使用？",
    trafficTips5: "关闭流量只针对本月，下月可正常使用",
    trafficTips6: "关闭后如需继续使用流量，可在【设备管理-设备列表】中开启",
    trafficTips7: "本月流量已关闭",
    trafficTips8: "如需继续使用请在【设备列表-设备详情】开启流量"
  },

  system: {
    systemManage: "系统管理",
    role: "角色管理",
    user: "用户管理",
    personCenter: "个人中心",
    safeZone: "安全区",

    dashboard: "数据看板",
    device: "设备管理",
    billing: "计费管理",
    message: "消息中心",
    system: "系统管理",

    devicelist: "设备列表",
    templatelist: "模版列表",
    devicegroup: "设备分组",
    myAccount: "我的账户",
    rechargeRecord: "充值记录",
    msgBilling: "计费消息",

    "dashboard-view": "访问",

    "devicelist-view": "访问",
    "devicelist-bind": "绑定设备",
    "devicelist-edit": "编辑",
    "devicelist-delete": "删除",
    "devicelist-hmi": "HMI屏",
    "devicelist-config": "组态画面",

    "templatelist-view": "访问",
    "templatelist-add": "新增模版",
    "templatelist-copy": "复制模版",
    "templatelist-edit": "编辑",
    "templatelist-delete": "删除",
    "templatelist-data-pm": "分配权限",

    "devicegroup-view": "访问",
    "devicegroup-add": "新增分组",
    "devicegroup-edit": "编辑",
    "devicegroup-delete": "删除",
    "devicegroup-data-pm": "分配权限",

    "myAccount-view": "访问",
    "myAccount-ca-charge": "Cloud Application充值",
    "myAccount-ca-allcoate": "TAG分配",
    "myAccount-ra-charge": "Remote Access充值",
    "myAccount-ra-allcoate": "计费卡分配",

    "rechargeRecord-view": "访问",

    "msgBilling-view": "访问",

    "user-view": "访问",
    "user-invite": "邀请用户",
    "user-edit": "变更角色",
    "user-delete": "删除",
    "user-data-pm": "数据权限",

    "role-view": "访问",
    "role-add": "新增角色",
    "role-edit": "编辑",
    "role-delete": "删除",

    "safeZone-view": "访问",
    "safeZone-add": "新增安全区",
    "safeZone-edit": "编辑",
    "safeZone-delete": "删除"
  },
  billing: {
    billingManage: "计费管理",
    myAccount: "我的账户",
    billingOverview: "计费总览",
    rechargeRecords: "充值记录",
    cardType: "计费卡类型",
    cardNumber: "卡号",
    cardName: "计费卡名",
    cloudAppCard: "Cloud Application",
    remoteAccessCard: "Remote Access",
    activeOp1: "输入卡号后8位",
    activeOp2: "刮开图层，输入卡密",
    activeOp3: "打开计费卡背面",
    activating: "生效中",
    Outdated: "已失效",
    noActivated: "未激活",
    activeDate: "激活日期",
    selectActiveDate: "选择激活日期",
    status: "状态",
    validityDate: "有效期",
    TagVal: "TAG有效期",
    opAccount: "操作账号",
    deviceLevel: "设备当前等级",
    deviceNowTag: "设备当前TAG（已使用/总数 • 个）",
    allocateDevice: "分配设备",
    allocateDate: "分配时间",
    selectAllocateDate: "选择分配时间",
    allocateLevel: "分配等级",
    allocateTagNum: "分配TAG数",
    allocateTagStatus: "分配TAG状态",
    allocateTagVal: "分配TAG有效期",
    allocateCard: "分配计费卡",
    sTagNum: "剩余TAG",
    sTemplateNum: "剩余模板",
    platformGifts: "平台赠送",
    rechargeNow: "立即充值",
    total: "总数",
    unit: "个",
    unit2: "张",
    used: "已使用",
    left: "剩余",
    tagAllocate: "TAG分配",
    cardAllocate: "计费卡分配",
    allocateTips1: "请将{0}分配给设备使用",
    allocateTips2: "请注意{0}有效期，以免未使用的{0}过期，造成资源浪费",
    allocateTips3: "分配给设备的{0}将始终使用于被分配设备，直到{0}过期",
    viewAll: "查看详情",
    template: "模板",
    cardUseAla: "计费卡使用分析",
    num: "数量",
    notSet: "暂不分配等级",
    keepNowLevel: "保持设备当前等级",
    allocateTips: "TAG数量 {0} 个 / 采集频率 {1} 分钟",
    level: "等级",
    CollFrequency: "采集频率",
    Minutes: "分钟",
    tagNumber: "TAG数量",
    month: "月",
    year: "年",
    deviceDetail: "设备详情",
    returnTag: "返回TAG分配",
    returnDetail: "返回TAG详情",
    deviceUnbind: "设备已解绑本账号",
    notThisAccount: "非本账号充值",
    levelDesc: "等级说明",
    allocateTips4: "● 平台赠送的TAG分配等级只能选择{0}",
    allocateTips5: "● TAG分配规则 (采集频率按当前等级为准) ",
    allocateTips6: "分配等级仅支持升级，不支持降级",
    allocateTips6_1: "若选择",
    allocateTips6_2: "再次分配时，分配等级只能选择",
    allocateTips7: "设备绑定账号改变，之前绑定账号给设备分配的未过期{0}，将始终跟着设备",
    allocateTips8: "未失效流量将始终跟着设备",
    optionalRange: "可选范围",
    unallocated: "待分配",
    nowAllocateTagNum: "当前可分配TAG数量",
    avaleBalance: "可用余额",
    CurrAllocate: "本次分配",
    allCardNum: "总张数",
    activedCardNum: "生效中",
    deactivedCardNum: "已失效",
    allocateSuccess: "分配成功",
    tagStatus: "TAG 状态",
    allocatedTagStatus: "分配TAG状态",
    traffic: "流量",
    trafficCard: "流量卡",
    trafficTips: "流量使用提醒",
    billcardPackage: "计费卡套餐",
    billcardActive: "计费卡激活",
    dataStorage: "数据存储",
    validityPeriod: "有效期",
    dataCollFrequency: "数据采集频率",
    templateNum: "模板数量",
    pleaseInput: "请输入",
    pleaseSelect: "请选择",
    numTip: "请输入卡号",
    passwordTip: "请输入卡密",
    InputEnAndNum8: "请输入8位英文与数字",
    InputEnAndNum16: "请输入8位英文与数字",
    activedTips: "温馨提示",
    activedTips1: "计费卡成功激活后立即生效，有效期1年",
    activedTips2: "请在有效期内使用卡内套餐",
    cardNum: "卡号",
    cardPassword: "卡密",
    InputPassword: "请输入8位卡密",
    InputNum: "请输入卡号后8位",
    successTip: "您的计费卡已成功激活",
    cardValidityDate: "计费卡有效期",
    successTip2: "请在有效期内使用卡内套餐",
    failTip: "你的计费卡激活失败",
    failReasonTip: "激活失败原因",
    next: "下一步",
    activeNow: "立即激活",
    gotIt: "知道了",
    actived: "生效中",
    deActived: "已失效",
    tagMessage: "TAG余额不足，无法继续分配，请使用Cloud Application计费卡充值。",
    action: "立即充值",
    know: "我知道了",
    description1: "点击立即充值后，自动确认本页面设置",
    cloudApplication: "Cloud Application",
    remoteAccess: "Remote Access",

    vncTips1: "VNC使用中，该功能会根据实际使用情况扣除流量，请合理使用！",
    disabledTemplates: "当前有{0}个模板TAG无法使用",
    tagInfo: "TAG 信息",
    requirements1: "要保证所有模板TAG都可使用",
    requirements2: "需满足设备TAG数≥模板TAG数",
    deviceTagCount: "设备可用TAG数",
    templateTagCount: "模板TAG数",
    vncTips2: "查看HMI屏，需根据实际使用扣除对应流量，请确认是否继续？",
    vncEnd: "VNC 使用结束",
    timeLimitReached: "您设置了VNC使用时长上限，时间已到，VNC自动关闭。",
    vncTimeSetting: "HMI屏观看时长设置",
    instruction: "当达到开启时长上限，将自动关闭VNC服务",
    tagAction: "设置时长上限",
    // trafficTips1: "该设备流量不足，如需继续使用，请前往DIACloud云平台使用Remote Access 计费卡充值。",
    trafficTips1: "该设备流量不足，如需继续使用，请使用Remote Access 计费卡充值。",
    trafficTips2: "目前设备流量不足，请避免再使用流量相关传输服务。",
    trafficTips3: "设备使用流量统计，请前往DIACloud 云平台【计费管理-账单】查看",
    trafficTips4: "该设备已被删除",
    trafficTips5: "VNC使用结束，该设备流量不足",

    selectCardType: "请选择卡规格",
    trafficTips6: "未分配的Remote Access，可在{0}进行分配设备",
    myCount: "我的账户-计费卡分配",
    activate: "激活",
    trafficTips7: "卡内套餐需分配到设备后才能使用，请在有效期内使用该套餐",
    notAllocate: "暂不分配",
    billingCard: "计费卡",
    trafficTips8: "设备一经分配不可修改",
    trafficTips9: "请谨慎操作",
    trafficTips10: "设备优先使用即将到期的流量卡",
    confirmAllocationTips: "是否确认把该套餐分配给{0}？",
    confirmAllocation: "确认分配",
    trafficPeriod: "流量有效期",
    billingCardTraffic: "计费卡流量",
    trafficTotal: "总流量",
    trafficUsed: "已使用流量",
    trafficLeft: "剩余流量",
    trafficStatus: "剩余流量状态",
    trafficLeftCard: "卡剩余流量",
    trafficLeftDevice: "设备剩余流量",
    billingCardUsage: "计费卡使用分析",
    trafficStatistics: "未失效的流量统计",
    trafficsLeftValid: "未失效的剩余流量",
    trafficsLeftTotal: "未失效的总流量",
    selectDateActive: "选择激活日期",
    selectDateUsedMin: "选择使用日期",
    selectDateUsedMax: "选择使用日期",
    DateofUse: "使用日期",
    trafficUsed2: "使用流量",
    trafficUsage: "流量消耗详情",

    freeDongle: "Dongle赠送",
    freeHMI: "人机赠送"
  },

  ERR_FR_TOO_MANY_REDIRECTS: "太多重定向",
  ERR_BAD_OPTION_VALUE: "无效的参数值",
  ERR_BAD_OPTION: "无效的参数",
  ERR_NETWORK: "网络错误",
  ERR_DEPRECATED: "已弃用",
  ERR_BAD_RESPONSE: "响应错误",
  ERR_BAD_REQUEST: "请求错误",
  ERR_NOT_SUPPORT: "不支持",
  ERR_INVALID_URL: "无效的 URL",
  ERR_CANCELED: "请求已取消",
  ECONNABORTED: "请求超时或被中止",
  ETIMEDOUT: "请求超时",
  ERR_EMAIL_EXIST: "邮箱已存在",
  E_INVALID_ID: "无效ID",
  E_GET_DEV_GROUP_DETAIL_ERR: "获取设备详情失败",
  E_USER_NOT_FOUNT: "未找到该用户",
  E_DEVICE_VNC_OFFLINE: "设备VNC已离线",
  E_MISSING_AUTH_HEADER: "TOKEN请求头丢失",
  E_TEMPLATE_CAN_NOT_DEL_WITH_BIND_DEV: "该模板已绑定设备",
  E_INVALID_PASSWD: "密码错误",
  E_INPUT_PWD_ERR: "密码输入错误",
  E_INVALID_OR_EXPIRED_TOKEN: "登录已过期, 请重新登录!",
  E_USER_NOT_FOUND: "用户不存在",
  ERR_PHONE_NOT_VERIFY: "验证码错误",
  ERR_ACCOUNT_EXIST: "账号已存在",
  ERR_EMAIL_NOT_ACTIVE: "邮箱未激活",
  E_BILLING_TAG_RECORD_FOUND: "卡号或卡密错误，请检查您的卡号或卡密",
  E_CLOUD_CARD_PWD_ERR: "卡号或卡密错误，请检查您的卡号或卡密",
  E_BILLING_REMOTE_ACCESS_RECORD_FOUND: "卡号或卡密错误，请检查您的卡号或卡密",
  E_CLOUD_CARD_ALREADY_ACTIVATE: "该计费卡已被激活，请勿重复激活",
  E_SAFEAREAS_NAME_EXIST: "安全区域名称已存在",
  E_SAFEAREAS_MORE_THAN_LIMIT: "最多添加20个安全区域",
  E_hdd_team_group_name_exist: "团队中分组不可重复",

  "1001": "认证错误",
  "1002": "权限不足",
  "1003": "暂无数据",
  "1004": "密码错误",
  "1005": "数据库错误",
  "1006": "输入参数错误",
  "1007": "服务器内部异常",
  "1008": "Auth Header缺失",
  "1009": "登录过期 请重新登录",
  "1010": "用户未找到",
  "1011": "验证码错误",
  "1012": "用户已存在",
  "1013": "邮件发送失败",
  "1014": "请到注册邮箱激活您的账号",
  "1015": "邮件达到限制",
  "1016": "手机号未验证",
  "1031": "邮箱未验证",
  "1017": "需要重新获取手机验证码",
  "1018": "验证码发生失败",
  "1019": "账号不匹配 (资源的账号不匹配)",
  "1020": "邮箱激活链接过期 需要重新注册",
  "1021": "参数错误",
  "1022": "验证码已过期",
  "1023": "未找到当前客户端的Session 信息  重新发送RAND",
  "1024": "请至HMI开启VNC",
  "1025": "非法Pin",
  "1026": "非法的Licnese",
  "1027": "非法的设备分组ID",
  "1028": "非法的HMISN",
  "1029": "非法的模板ID",
  "1030": "模板名称已存在",
  "1032": "该分组或其子分组存在设备，不可删除",
  "1033": "模板数量已达上限",
  "1034": "设备名称已存在",
  "1037": "该模板存在关联设备，不可删除",
  "1042": "切换模板时与新模板的模板类型不匹配",
  "2001": "解析DIAView Tar包异常",
  "1043": "卡号或卡密错误，请检查您的卡号或卡密",
  "1044": "计费卡已经过了允许的激活时间",
  "1045": "该计费卡已被激活，请勿重复激活",
  "1050": "该计费卡已被激活，请勿重复激活",
  "1055": "账号已注销",
  "1063": "角色名称重复",
  "1065": "最多添加20个安全区域",
  "1066": "安全区域名称已存在",
  "1069": "该账号已加入其他团队",
  "1071": "该账号不在当前团队内",
  "1078": "非当前数据中心账号",
  "1083": "团队中分组不可重复",
  "2003": "网络错误"
}
