import { createApp, Directive } from "vue"
import App from "./App.vue"
import { i18n } from "./utils/i18n"
import Router from "./router"
import "ant-design-vue/dist/reset.css"
import IconFont from "./assets/iconfont/custom-icon"
import message from "ant-design-vue/es/message"
import { createPinia } from "pinia"
import * as directives from "@/utils/directive"

message.config({
  duration: 2.5,
  maxCount: 3,
  rtl: true,
  prefixCls: "global-message"
})

const app = createApp(App).use(Router).use(i18n).use(createPinia())
// 自定义指令
Object.keys(directives).forEach((key) => {
  app.directive(key, (directives as { [key: string]: Directive })[key])
})
app.component("IconFont", IconFont)
app.mount("#app")

// app.config.globalProperties.$api = "191"
//const { proxy } = getCurrentInstance() as any
