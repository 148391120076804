import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { InternalAxiosRequestConfig } from "axios"
import { i18n } from "./i18n"
import { getAllToken, getToken, removeToken, setToken } from "@/utils/index"
import router from "@/router"
import message from "ant-design-vue/es/message"
import { getUserInfo, refreshToken } from "@/services"
const t = i18n.global.t

export interface ResDataType<T> {
  code?: number
  data?: T
  msg?: string
}
let source = axios.CancelToken.source()
const instance = axios.create({
  timeout: 60000,
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true
})

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  if (!config.headers.Authorization && getToken()) {
    config.headers.Authorization = getToken()
  }
  return config
})

let refreshTarget = false
const requests: Array<{ next: Function }> = []
const refreshing = Object.create(null)
Object.defineProperty(refreshing, "value", {
  get() {
    return refreshTarget
  },
  set(v) {
    refreshTarget = !!v
    if (!v) {
      requests.forEach((instance) => instance.next())
      requests.length = 0
    }
  }
})
instance.interceptors.response.use(
  async ({ config, data }) => {
    // debugger
    if (data.code == 0) {
      return data
    } else if (data.code == 1055) {
      source.cancel("1055")
      // source = axios.CancelToken.source()
      removeToken()
      router.replace("login")
      return Promise.reject({ data: "1055" })
      // return message.error(t("1055"))
    } else if (data.code == 1008 || data.code == 1009) {
      if (config.url?.includes("refreshtoken")) {
        return Promise.reject({ data: "E_INVALID_OR_EXPIRED_TOKEN" })
      }

      if (refreshing.value) {
        return await new Promise((resolve, reject) => {
          config.headers.Authorization = ""
          const next = async () => {
            try {
              const res = await instance({ ...config, cancelToken: source.token })
              resolve(res)
            } catch (err) {
              reject(err)
            }
          }
          requests.push({ next })
        })
      } else {
        try {
          refreshing.value = true
          const token = getAllToken()
          if (!(token.token && token.rToken)) throw "noToken"
          const { data: newToken, msg } = await refreshToken(token.rToken, token.token)
          if (msg) throw "refreshErr"
          setToken(newToken, token.rToken)
          config.headers.Authorization = ""
          return await instance(config)
        } catch (err) {
          refreshing.value = false
          source.cancel("401")
          source = axios.CancelToken.source()
          removeToken()
          localStorage.removeItem("HOST")
          router.replace("login")
          if (err == "refreshErr") {
            return Promise.reject({ data: "E_INVALID_OR_EXPIRED_TOKEN" })
          } else {
            return null
          }
        } finally {
          refreshing.value = false
        }
      }
    } else if (data.code) {
      // return Promise.reject(data)
      return Promise.reject({ data: data.code, realData: data.data })
    } else {
      return Promise.reject({ data: "ERR_BAD_RESPONSE" })
    }
  },
  (err: AxiosError) => {
    return Promise.reject(err)
  }
)

type CusCofing = {
  showMask?: boolean
  manual?: boolean
  duration?: number
} & AxiosRequestConfig

const request = async <T>(config: CusCofing): Promise<ResDataType<T>> => {
  const duration = config?.duration || 2.5
  try {
    let host = localStorage.getItem("HOST")
    instance.defaults.baseURL = host + "/v1"

    if (host && import.meta.env.PROD) {
      instance.defaults.baseURL = host + "/v1"
    } else {
      instance.defaults.baseURL = import.meta.env.VITE_API_URL
    }
    const whiteList = [
      "login",
      "removal",
      "register",
      "forgetverify",
      "modifyPwd",
      "phoneverify",
      "resendmail",
      "/global/activate"
    ]
    if (whiteList.some((key) => config?.url?.includes(key))) {
      instance.defaults.baseURL = import.meta.env.VITE_API_URL
    }
    const response = await instance({ ...config, cancelToken: source.token })
    return { data: response.data }
  } catch (error: any) {
    console.log(error)

    if (error?.name == "AxiosError" && config?.manual) {
      return { msg: error.code }
    }
    if (error?.name == "AxiosError") {
      message.error(`${t(error.code)}`, duration)
      return Promise.reject({ msg: error.code })
    } else if (error?.name == "CanceledError") {
      return Promise.reject({ msg: error.code })
    } else if (!config?.manual) {
      message.error(`${t(error.data)}`, duration)
      //请求成功,但是返回错误
      return { msg: error.data, code: error?.code }
    } else {
      return { msg: error.data, code: error?.code, data: error.realData }
    }
  }
}

export { instance, request }
