import { defineStore } from "pinia"
import { getUserInfo as getUserInfoApi } from "@/services"
import { RowMeta } from "@/router/routerMap"

export type UserInfoType = {
  dbuser_id: number
  data_center: string
  name: string
  created_at: string
  contact: string
  alias_name: string
  dgroup_id: number
  template_max_num: number
  template_max_id: number
  team_name: string
  is_main: boolean
  member_ext: boolean
  permissions: number[]
}

export const useUserInfo = defineStore("USER_INFO", () => {
  const userInfo = ref<UserInfoType>()
  const menuList = ref([] as RowMeta[])

  const getUserInfo = async () => {
    if (!userInfo.value) {
      const { data } = await getUserInfoApi()
      userInfo.value = data
    }
  }

  const setMenuList = (items: any) => {
    menuList.value = items
  }

  const $reset = () => {
    userInfo.value = undefined
    menuList.value = []
  }

  return { menuList, userInfo, getUserInfo, setMenuList, $reset }
})
