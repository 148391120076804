/*
 * @Description: 权限指令
 */
import { Directive, DirectiveBinding } from "vue"
import { useUserInfo } from "@/store"
import { getOpList } from "@/data/permission"

export const permission: Directive = {
  async mounted(el: HTMLDivElement, binding: DirectiveBinding) {
    const userStore = useUserInfo()
    await userStore.getUserInfo()
    const { value } = binding
    const hasOpList = userStore.userInfo?.permissions
    const AllOpObj = getOpList()
    //permissions返回空数组有所有权限
    const hasPermission =
      value == "hasPermission" || userStore.userInfo?.is_main || hasOpList?.find((op) => AllOpObj[op] == value)

    if (!hasPermission) {
      el.style.display = "none"
    } else {
      el.style.display = "visible"
    }
  }
}
